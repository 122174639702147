






























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'
import { Form as ElForm, Input } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import { isValidUsername } from '@/utils/validate'
import { AuthModule } from '@/store/modules/auth'
import store from '@/store'

@Component({
  name: 'Login'
})
export default class extends Vue {
  private validateUsername = (rule: any, value: string, callback: Function) => {
    // if (!isValidUsername(value)) {
    //   callback(new Error('Please enter the correct user name'))
    // } else {
      callback()
    //}
  }

  private validatePassword = (rule: any, value: string, callback: Function) => {
    if (value.length < 8) {
      callback(new Error('The password can not be less than 8 digits'))
    } else {
      callback()
    }
  }

  private loginForm = {
    username: '',
    password: ''
  }

  private loginRules = {
    username: [{ validator: this.validateUsername, trigger: 'blur' }],
    password: [{ validator: this.validatePassword, trigger: 'blur' }]
  }

  private passwordType = 'password'
  private loading = false
  private showDialog = false
  private redirect?: string
  private otherQuery: Dictionary<string> = {}

  @Watch('$route', { immediate: true })
  private onRouteChange(route: Route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>
    if (query) {
      this.redirect = query.redirect
      this.otherQuery = this.getOtherQuery(query)
    }
  }

  mounted() {
    if (this.loginForm.username === '') {
      (this.$refs.username as Input).focus()
    } else if (this.loginForm.password === '') {
      (this.$refs.password as Input).focus()
    }
  }

  private showPwd() {
    if (this.passwordType === 'password') {
      this.passwordType = ''
    } else {
      this.passwordType = 'password'
    }
    this.$nextTick(() => {
      (this.$refs.password as Input).focus()
    })
  }

  private handleLogin() {
    (this.$refs.loginForm as ElForm).validate(async(valid: boolean) => {
      if (valid) {
        this.loading = true
        //await UserModule.Login(this.loginForm)
        await store.dispatch("auth/login", {
        email: this.loginForm.username,
        password: this.loginForm.password,
      })
      .then((res) => {
        this.loading = false
        if(this.redirect) {
          //debugger
          this.$router.push({path: `${this.redirect}`})
        } else {
          this.$router.push({name: 'Dashboard'})
        }
      })
      .catch((error) => {
        this.loading = false
        this.$message({showClose: true, type: 'error', duration: 0, message: 'Invalid username or password.'})
      })
        
      
        // this.$router.push({
        //   path: this.redirect || '/',
        //   query: this.otherQuery
        // })
        // Just to simulate the time of the request
        // setTimeout(() => {
        //   this.loading = false
        // }, 0.5 * 1000)
      } else {
        return false
      }
    })
  }

  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur]
      }
      return acc
    }, {} as Dictionary<string>)
  }
}
